import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Button, Form, Input, Typography, notification } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Link, navigate } from 'gatsby';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import LocalizedStrings from 'react-localization';
import store from 'store';

import { ClientHelmet } from '../components';
import { FormPageLayout } from '../components/Layouts';
import { GlobalContext } from '../context/GlobalContextProvider';
import { apiRequester, handleError, handleSuccess } from '../utility';
import { useForm } from 'antd/es/form/Form';

const { Title, Paragraph } = Typography;
const GATSBY_STYLE_FORM_PAGE_FORM_BTN_BORDER_COLOR = process.env.GATSBY_STYLE_FORM_PAGE_FORM_BTN_BORDER_COLOR!;
const GATSBY_STYLE_FORM_PAGE_FORM_COLOR = process.env.GATSBY_STYLE_FORM_PAGE_FORM_COLOR || 'white';
const GATSBY_GOOGLE_RECAPTCHA_KEY = process.env.GATSBY_GOOGLE_RECAPTCHA_KEY!;
const strings = new LocalizedStrings({
    en: {
        authNotFound: 'Auth module not found',
        accessGranted: 'Access granted!',
        logIntoVirtooally: 'Log into Virtooally',
        enterLoginDetails: 'Enter your login details below.',
        emailAddress: 'Email Address',
        emailAddressRequired: 'Please enter your email!',
        password: 'Password',
        passwordRequired: 'Please enter your password!',
        logIn: 'Log in',
        forgotPassword: 'Forgot your password?',
        register: "Don't have an account? Register here",
        botCheck: 'Please verify you are not a bot',
    },
    de: {
        authNotFound: 'Authentifizierung konnte nicht erfolgen',
        accessGranted: 'Sie werden eingeloggt',
        logIntoVirtooally: 'Bei Virtooally einloggen',
        enterLoginDetails: 'Bitte geben Sie hier Ihre Logindaten ein',
        emailAddress: 'E-Mail-Adresse',
        emailAddressRequired: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
        password: 'Passwort',
        passwordRequired: 'Bitte geben Sie Ihr Passwort ein',
        logIn: 'Einloggen',
        forgotPassword: 'Haben Sie Ihr Passwort vergessen?',
        register: 'Sie haben noch keinen Account? Bitte registrieren Sie sich hier. ',
        botCheck: 'Bitte verifizieren Sie, dass Sie kein Roboter sind',
    },
});

const Captcha = ({
    setCaptchaToken,
}: {
    setCaptchaToken: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
    return (
        <GoogleReCaptcha
            onVerify={(token: string) => {
                setCaptchaToken(token);
            }}
        />
    );
};

const LoginPage = () => {
    const context = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const { eventAuthModule, fetchEvent } = context;
    const breakpoint = useBreakpoint();
    const [captchaToken, setCaptchaToken] = useState<string | undefined>();
    const [captchaMandatory, setCaptchaMandatory] = useState<boolean>(false);
    const [loginForm] = useForm();

    useEffect(() => {
        setLoading(true);
        fetchEvent()
            .then(({ eventAuthModule }) => {
                const authModuleId = eventAuthModule?._id!;
                if (authModuleId) {
                    return apiRequester.getCaptchaCompulsion({ moduleId: authModuleId });
                } else return Promise.resolve({ enableCaptchaAtLogin: false });
            })
            .then(({ enableCaptchaAtLogin }: { enableCaptchaAtLogin: boolean }) => {
                setCaptchaMandatory(enableCaptchaAtLogin);
            })
            .catch(handleError)
            .finally(() => setLoading(false));

        if (eventAuthModule && eventAuthModule.moduleData?.enableCaptcha) setCaptchaMandatory(true);
    }, []);

    const onFinish = async (values: { emailId: string; password: string }) => {
        try {
            setLoading(true);
            if (!eventAuthModule) throw new Error(strings.authNotFound);
            const loginData = await apiRequester.login({ eventAuthModule, ...values, captchaToken });
            await store.set('token', loginData.token);
            await store.set('refreshToken', loginData.refreshToken);
            await store.set('user', loginData.user);
            handleSuccess(strings.accessGranted!);
            setLoading(false);
            navigate(`/${typeof window !== 'undefined' ? window.location.search : ''}`);
        } catch (err) {
            handleError(err);
            setLoading(false);
        }
    };

    return (
        <GoogleReCaptchaProvider reCaptchaKey={GATSBY_GOOGLE_RECAPTCHA_KEY}>
            <FormPageLayout>
                <ClientHelmet />
                <div style={{ marginBottom: '2rem' }}>
                    <Title
                        level={2}
                        style={{
                            marginBottom: '0',
                            color: GATSBY_STYLE_FORM_PAGE_FORM_COLOR,
                            textAlign: breakpoint.md ? 'left' : 'center',
                        }}
                    >
                        {strings.logIn}
                    </Title>
                    <Paragraph
                        style={{
                            color: GATSBY_STYLE_FORM_PAGE_FORM_COLOR,
                            textAlign: breakpoint.md ? 'left' : 'center',
                        }}
                    >
                        {strings.enterLoginDetails}
                    </Paragraph>
                </div>
                <Form onFinish={onFinish} form={loginForm}>
                    <Form.Item
                        label={<span style={{ color: GATSBY_STYLE_FORM_PAGE_FORM_COLOR }}>{strings.emailAddress}</span>}
                        labelCol={{ span: 24 }}
                        name="emailId"
                        rules={[{ required: true, type: 'email', message: strings.emailAddressRequired }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<span style={{ color: GATSBY_STYLE_FORM_PAGE_FORM_COLOR }}>{strings.password}</span>}
                        labelCol={{ span: 24 }}
                        name="password"
                        rules={[{ required: true, message: strings.passwordRequired }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    {captchaMandatory && <Captcha setCaptchaToken={setCaptchaToken} />}
                    <Form.Item>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: breakpoint.md ? 'row' : 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                style={{
                                    borderColor: GATSBY_STYLE_FORM_PAGE_FORM_BTN_BORDER_COLOR,
                                    marginBottom: breakpoint ? '1rem' : undefined,
                                }}
                                block={!breakpoint.md}
                            >
                                {strings.logIn}
                            </Button>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    textAlign: breakpoint.md ? 'right' : 'center',
                                }}
                            >
                                <a
                                    style={{ color: GATSBY_STYLE_FORM_PAGE_FORM_COLOR }}
                                    onClick={() => {
                                        // Using window.location in favour of skipping preload wait time
                                        if (typeof window !== 'undefined') {
                                            const url = `/register${
                                                typeof window !== 'undefined' ? window.location.search : ''
                                            }`;
                                            window.location.href = url;
                                        }
                                    }}
                                >
                                    {strings.register}
                                </a>
                                <Link
                                    style={{ color: GATSBY_STYLE_FORM_PAGE_FORM_COLOR }}
                                    to={`/forgot-password${
                                        typeof window !== 'undefined' ? window.location.search : ''
                                    }`}
                                >
                                    {strings.forgotPassword}
                                </Link>
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            </FormPageLayout>
        </GoogleReCaptchaProvider>
    );
};

export default LoginPage;
